<template>
    <div>
        <Header />
        <section id="mainContent" class="d-flex">
            <LeftSide />
            <router-view />
        </section>
    </div>
</template>
<script>
import Header from '../Header.vue'
import LeftSide from '../LeftSide.vue'

export default {
  name: 'HomeLayout',
  components: {
    Header,
    LeftSide
  }
}
</script>
<style lang="css" scoped>
    @import '../../assets/css/bootstrap.min.css';
    @import '../../assets/css/style.css';
</style>