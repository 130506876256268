<template>
    <header>
        <notification v-if="hasMessage"/>
        <section id="header">
            <div v-if="isMobile" class="row align-items-center" >
                <div class="col-3" style="padding:0;margin:0;">
                    <router-link to="/">
                        <img src="@/assets/images/starlogo.png" style="width:40px;" alt="">
                    </router-link>
                </div>
                <div class="col-9 flex" 
                    style="padding:0;margin:0; justify-content: flex-end;">
                    <div class="btn ml-3 walletBtn" style="padding-top:10px;"
                        @click="connectWallet" v-if="!isMetaMaskConnected">
                        <i class="pr-md-2">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 334.877 334.877" style="enable-background:new 0 0 334.877 334.877;" xml:space="preserve">
                            <g>
                                <path d="M333.196,155.999h-16.067V82.09c0-17.719-14.415-32.134-32.134-32.134h-21.761L240.965,9.917
                                    C237.571,3.798,231.112,0,224.107,0c-3.265,0-6.504,0.842-9.364,2.429l-85.464,47.526H33.815
                                    c-17.719,0-32.134,14.415-32.134,32.134v220.653c0,17.719,14.415,32.134,32.134,32.134h251.18
                                    c17.719,0,32.134-14.415,32.134-32.134v-64.802h16.067V155.999z M284.995,62.809c9.897,0,17.982,7.519,19.068,17.14h-24.152
                                    l-9.525-17.14H284.995z M220.996,13.663c3.014-1.69,7.07-0.508,8.734,2.494l35.476,63.786H101.798L220.996,13.663z
                                    M304.275,302.742c0,10.63-8.651,19.281-19.281,19.281H33.815c-10.63,0-19.281-8.651-19.281-19.281V82.09
                                    c0-10.63,8.651-19.281,19.281-19.281h72.353L75.345,79.95H37.832c-3.554,0-6.427,2.879-6.427,6.427s2.873,6.427,6.427,6.427h14.396
                                    h234.83h17.217v63.201h-46.999c-21.826,0-39.589,17.764-39.589,39.589v2.764c0,21.826,17.764,39.589,39.589,39.589h46.999V302.742z
                                    M320.342,225.087h-3.213h-59.853c-14.743,0-26.736-11.992-26.736-26.736v-2.764c0-14.743,11.992-26.736,26.736-26.736h59.853
                                    h3.213V225.087z M276.961,197.497c0,7.841-6.35,14.19-14.19,14.19c-7.841,0-14.19-6.35-14.19-14.19s6.35-14.19,14.19-14.19
                                    C270.612,183.306,276.961,189.662,276.961,197.497z"/>
                            </g>
                            </svg>
                        </i>
                        Connect
                    </div>
                    <div class="btn ml-3 walletBtn" @click="lockMetamask" v-if="isMetaMaskConnected"  style="padding-top:10px;">
                        <i class="pr-md-2 pr-0">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 334.877 334.877" style="enable-background:new 0 0 334.877 334.877;" xml:space="preserve">
                            <g>
                                <path d="M333.196,155.999h-16.067V82.09c0-17.719-14.415-32.134-32.134-32.134h-21.761L240.965,9.917
                                    C237.571,3.798,231.112,0,224.107,0c-3.265,0-6.504,0.842-9.364,2.429l-85.464,47.526H33.815
                                    c-17.719,0-32.134,14.415-32.134,32.134v220.653c0,17.719,14.415,32.134,32.134,32.134h251.18
                                    c17.719,0,32.134-14.415,32.134-32.134v-64.802h16.067V155.999z M284.995,62.809c9.897,0,17.982,7.519,19.068,17.14h-24.152
                                    l-9.525-17.14H284.995z M220.996,13.663c3.014-1.69,7.07-0.508,8.734,2.494l35.476,63.786H101.798L220.996,13.663z
                                    M304.275,302.742c0,10.63-8.651,19.281-19.281,19.281H33.815c-10.63,0-19.281-8.651-19.281-19.281V82.09
                                    c0-10.63,8.651-19.281,19.281-19.281h72.353L75.345,79.95H37.832c-3.554,0-6.427,2.879-6.427,6.427s2.873,6.427,6.427,6.427h14.396
                                    h234.83h17.217v63.201h-46.999c-21.826,0-39.589,17.764-39.589,39.589v2.764c0,21.826,17.764,39.589,39.589,39.589h46.999V302.742z
                                    M320.342,225.087h-3.213h-59.853c-14.743,0-26.736-11.992-26.736-26.736v-2.764c0-14.743,11.992-26.736,26.736-26.736h59.853
                                    h3.213V225.087z M276.961,197.497c0,7.841-6.35,14.19-14.19,14.19c-7.841,0-14.19-6.35-14.19-14.19s6.35-14.19,14.19-14.19
                                    C270.612,183.306,276.961,189.662,276.961,197.497z"/>
                            </g>
                            </svg>
                        </i>
                        {{showWallet(this.$store.state.account.address)}}
                    </div>
                    <div id="menu" @click="openMenu">
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <title/>
                            <g data-name="Layer 2" id="Layer_2">
                                <path d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z"/>
                                <path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"/>
                                <path d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"/>
                            </g>
                            <g id="frame">
                                <rect class="cls-menu" height="32" width="32" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div v-if="!isMobile" class="row align-items-center justify-content-between">
                <div class="col-2 text-center text-sm-left">
                    <router-link to="/" id="pressDave" class="d-none d-md-inline-block" >
                    </router-link>
                </div>
                <div class="col-10 text-center text-sm-right">
                    <div class="btn ml-3 walletBtn" @click="connectWallet" v-if="!isMetaMaskConnected">
                        <i class="pr-md-2 pr-0">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 334.877 334.877" style="enable-background:new 0 0 334.877 334.877;" xml:space="preserve">
                            <g>
                                <path d="M333.196,155.999h-16.067V82.09c0-17.719-14.415-32.134-32.134-32.134h-21.761L240.965,9.917
                                    C237.571,3.798,231.112,0,224.107,0c-3.265,0-6.504,0.842-9.364,2.429l-85.464,47.526H33.815
                                    c-17.719,0-32.134,14.415-32.134,32.134v220.653c0,17.719,14.415,32.134,32.134,32.134h251.18
                                    c17.719,0,32.134-14.415,32.134-32.134v-64.802h16.067V155.999z M284.995,62.809c9.897,0,17.982,7.519,19.068,17.14h-24.152
                                    l-9.525-17.14H284.995z M220.996,13.663c3.014-1.69,7.07-0.508,8.734,2.494l35.476,63.786H101.798L220.996,13.663z
                                    M304.275,302.742c0,10.63-8.651,19.281-19.281,19.281H33.815c-10.63,0-19.281-8.651-19.281-19.281V82.09
                                    c0-10.63,8.651-19.281,19.281-19.281h72.353L75.345,79.95H37.832c-3.554,0-6.427,2.879-6.427,6.427s2.873,6.427,6.427,6.427h14.396
                                    h234.83h17.217v63.201h-46.999c-21.826,0-39.589,17.764-39.589,39.589v2.764c0,21.826,17.764,39.589,39.589,39.589h46.999V302.742z
                                    M320.342,225.087h-3.213h-59.853c-14.743,0-26.736-11.992-26.736-26.736v-2.764c0-14.743,11.992-26.736,26.736-26.736h59.853
                                    h3.213V225.087z M276.961,197.497c0,7.841-6.35,14.19-14.19,14.19c-7.841,0-14.19-6.35-14.19-14.19s6.35-14.19,14.19-14.19
                                    C270.612,183.306,276.961,189.662,276.961,197.497z"/>
                            </g>
                            </svg>
                        </i>
                        Connect Wallet
                    </div>
                    <div class="btn ml-3 walletBtn" @click="lockMetamask" v-if="isMetaMaskConnected">
                        <i class="pr-md-2 pr-0">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 334.877 334.877" style="enable-background:new 0 0 334.877 334.877;" xml:space="preserve">
                            <g>
                                <path d="M333.196,155.999h-16.067V82.09c0-17.719-14.415-32.134-32.134-32.134h-21.761L240.965,9.917
                                    C237.571,3.798,231.112,0,224.107,0c-3.265,0-6.504,0.842-9.364,2.429l-85.464,47.526H33.815
                                    c-17.719,0-32.134,14.415-32.134,32.134v220.653c0,17.719,14.415,32.134,32.134,32.134h251.18
                                    c17.719,0,32.134-14.415,32.134-32.134v-64.802h16.067V155.999z M284.995,62.809c9.897,0,17.982,7.519,19.068,17.14h-24.152
                                    l-9.525-17.14H284.995z M220.996,13.663c3.014-1.69,7.07-0.508,8.734,2.494l35.476,63.786H101.798L220.996,13.663z
                                    M304.275,302.742c0,10.63-8.651,19.281-19.281,19.281H33.815c-10.63,0-19.281-8.651-19.281-19.281V82.09
                                    c0-10.63,8.651-19.281,19.281-19.281h72.353L75.345,79.95H37.832c-3.554,0-6.427,2.879-6.427,6.427s2.873,6.427,6.427,6.427h14.396
                                    h234.83h17.217v63.201h-46.999c-21.826,0-39.589,17.764-39.589,39.589v2.764c0,21.826,17.764,39.589,39.589,39.589h46.999V302.742z
                                    M320.342,225.087h-3.213h-59.853c-14.743,0-26.736-11.992-26.736-26.736v-2.764c0-14.743,11.992-26.736,26.736-26.736h59.853
                                    h3.213V225.087z M276.961,197.497c0,7.841-6.35,14.19-14.19,14.19c-7.841,0-14.19-6.35-14.19-14.19s6.35-14.19,14.19-14.19
                                    C270.612,183.306,276.961,189.662,276.961,197.497z"/>
                            </g>
                            </svg>
                        </i>
                        {{showWallet(this.$store.state.account.address)}}
                    </div>
                </div>
            </div>
        </section>
    </header>
</template>
<script>
    import Notification from '../components/notification.vue';
    import Web3 from "web3/lib"
    export default {
        components: {
            Notification
        },
        data () {
            return {
            }
        },
        mounted () {
            var localAddress = localStorage.getItem("metaMaskAddress");
            try {
                if (localAddress && localAddress.length > 0) {
                    this.$store.dispatch("connect");
                }
            } catch (error) {
                return;
            }
        },
        computed: {
            isMobile() {
                return this.$q.screen.width < 996;
            },
            isMetaMaskInstalled() {
                const { ethereum } = window;
                return Boolean(ethereum && ethereum.isMetaMask);
            },
            isMetaMaskConnected() {
                return this.$store.state.account!=null;
            },
            hasMessage() {
                return this.$store.state.messageContent!=null;
            },
        },
        watch: {
            
        },
        methods: {
            openMenu() {
                if (document.getElementById("leftSide").style.display == 'block')
                    document.getElementById("leftSide").style.display = 'none';
                else {
                    document.getElementById("leftSide").style.display = 'block';
                }
            },
            goStarSwap() {
                let r = this.$router.resolve({
                    name: 'StarSwap', // put your route information in
                });
                window.location.assign(r.href);
            },
            connectWallet() {          
                this.$store.dispatch("connect");
            },
            lockMetamask() {
                this.$store.dispatch("disconnect");
            },
            addSTARToken() {
                this.$store.dispatch("addToken");
            },
            showWallet(wallet) {
                if (this.$q.screen.width >= 996)
                    return (
                        wallet.substring(0, 6) +
                        "..." +
                        wallet.substring(wallet.length - 5, wallet.length)
                    );
                else
                    return (
                        wallet.substring(0, 4) +
                        "..." +
                        wallet.substring(wallet.length - 3, wallet.length)
                    );
            },
        }
    }
</script>

<style>
    .cls-menu {
        fill:none;
        border:none;
    }

    #header svg {
        stroke: none !important;
    }
</style>
